@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  margin: 0;
  font-family: "Amiri", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.link {
  text-decoration: underline;
  color: #adaaa4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.instagram-media {
  min-width: 250px !important;
}

/* How I Help Page — Text Loop Banner */

#text-cycle:after {
  -webkit-animation: cycle 11s infinite; /* Safari 4+ */
  -moz-animation: cycle 11s infinite; /* Fx 5+ */
  -o-animation: cycle 11s infinite; /* Opera 12+ */
  animation: cycle 11s infinite; /* IE 10+, Fx 29+ */
  animation-name: text-cycle;
  animation-duration: 11s;
  animation-iteration-count: infinite;
  content: "";
}

@keyframes text-cycle {
  0% {
    content: "Safety";
  }
  9% {
    content: "Respect";
  }
  18% {
    content: "Empathy";
  }
  27% {
    content: "Accountability";
  }
  36% {
    content: "Trust";
  }
  45% {
    content: "Accessibility";
  }
  54% {
    content: "Presence";
  }
  63% {
    content: "Compassion";
  }
  72% {
    content: "Curiousity";
  }
  81% {
    content: "Faith/Hope";
  }
  90% {
    content: "Love";
  }
  100% {
    content: "Safety";
  }
}
